$black:#000;
$white:#fff;
$farbe1:#52277b;
$farbe2:#ef2f69;
$lightbg: mix($farbe1, #eee, 10%);
$lightgray: #e8e8e8;

$bodybg: $white;

$textcolor:#000;
$footertext:#fff;

$formerror: red;

/*
@mixin borderradius {
    border-radius: rem-calc(10);
}
*/

$buttonbg:$farbe2;
$buttonbg-hover:lighten($buttonbg, 10%);

:root {
    --layoutgridgap:2rem;

    @include rem-calc-sl(--lrminmargin, 120, math.div(20, 120));
}

